.list-view,
.grid-view {
    top: -6px;
}
.list-view.selected,
.grid-view.selected {
    color: #95c731;
}

.showcase-container {
    border-bottom: solid #d9d9d9 1px;
}

.discount-circle {
    height: 45px;
    width: 45px;
    padding-top: 12px;
    border-radius: 25px;
    color: #ffffff;
    background-color: #95c731;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
}

.store-product-list {
    padding-bottom: 80px;

    .loader-container{
        margin-top: 20px;
        margin-bottom: 0;
    }

    .heading {
        font-size: 25px;
        text-align: left;
        color: #000000;
        margin-top: 25px;
        margin-bottom: 30px;
        .text-2 {
            text-transform: uppercase;
            color: #95c731;
        }
    }

    .products-list-components-wrapper{
        background-color: #d8d8d8;
        margin-top: -1px;
        &:last-of-type {
            .collapse.in, .collapsing{
                margin-top: 30px;
            }
        }
        /*&:not(:last-of-type) {
            .products-list-components-row {
                margin-top: -1px;
            }
        }*/

        .products-list-components-row{
            padding-top: 30px;
            &.last{
                padding-bottom: 30px;
            }
        }

        .open-components-triangle{
            position: absolute;
            border-color: white transparent transparent;
            border-width: 10px 10px 0px;
            border-style: solid;
            width: 0px;
            height: 0px;
            line-height: 0px;
            top: -1px;
            display: none;
        }

        &.one-product-components-row{
            .one{
                .open-components-triangle{
                    left: 18.70%;
                }
            }
        }

        &.two-products-components-row{
            .one{
                .open-components-triangle{
                    left: 18.70%;
                }
            }
            .two{
                .open-components-triangle{
                    left: 67.60%;
                }
            }
        }

        &.three-products-components-row{
            .one{
                .open-components-triangle{
                    left: 18.70%;
                }
            }
            .two{
                .open-components-triangle{
                    left: 51.70%;
                }
            }
            .three{
                .open-components-triangle{
                    left: 83.70%
                }
            }
        }

    }

    .product-list-page:first-of-type {
        margin-top: -10px;
    }

    .products-list-row {
        &.root-products-row{
            padding-top: 30px;
            padding-bottom: 30px;
            &.components-shown {
                border-bottom: none;
            }
        }
        &.three-products-row {
            .product-title {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    .product-row-wrapper {
        border-bottom: 1px solid #d9d9d9;
        &.last {
            border: none;
            .products-list-row.root-products-row {
               padding-bottom: 0;
            }
        }
    }

    .product-info {

        .product-info-img {
            width: 160px;
			/*
            display: inline-block;
            background-position: center center;
            background-size: cover;
			*/
            position: relative;
            overflow: hidden;
            img {
                max-height: 250px;
            }
        }

        .product-postage-price,
        .product-postage-time {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            text-transform: uppercase;
            color: #95c731;
        }
        .product-title {
            font-size: 25px;
            line-height: 29px;
            text-align: left;
            color: #000000;
            padding-top: 10px;
        }
        .product-category{
            font-weight: bold;
            text-transform: uppercase;
            padding-top: 10px;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
        }
        .product-author {
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            padding-top: 5px;
        }

        .product-sinopse {
            padding-top: 15px;
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            color: #000000;
        }

        .product-price {
            padding-top: 10px;
            .pvp-price {
                font-weight: bold;
                font-size: 18px;
                text-align: left;
                color: #95c731;
            }
            .cover-price {
                font-size: 14px;
                text-decoration: line-through;
                color: #9d9d9d;
                padding-left: 5px;
            }
        }

        .product-rating {
            position: relative;
            left: -15px;
            float: left;
            margin-top: -5px;
            .icon-e, .icon-E {
                font-size: 50px;
                color: #95c731;
                display: inline-block;
                width: 20px;
                float: left;
            }
            .product-reviews-number{
                float: left;
                line-height: 52px;
                margin-left: 25px;
            }
        }

        .product-components-toggle{
            text-decoration: underline;
            padding-top: 10px;
            color: #95c731;
            cursor: pointer;
            &.collapsed{
                color: #000;
            }
        }

        .product-info-img-container {
          position:relative;
          display:inline-block;
          text-align:center;
          height: 250px;		  		  
          //overflow: hidden;
          &:hover {
            .product-list-buy-button.hidden-buy-button{
              display:block;
            }
          }
        }
    }

    .product-list-count-info {
        margin-top: 30px;
        span {
            font-size: 14px;
            text-align: center;
            color: #9d9d9d;
        }
    }
    .product-list-view-more-container {
        margin-top: 35px;
        font-weight: 600;

        &.error-loading {
            margin-top: 10px;
        }
        .product-list-view-more-btn {
            display: block;
            border: solid #000 1px;
            font-weight: 600;
            font-size: 11px;
            text-align: center;    
            text-transform: uppercase;
            color: #000;
            padding: 18px 20px;
            line-height: 12px;
            cursor: pointer;
        }
    }
}
