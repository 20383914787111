@import "client";
@import "general/loader";

%bootstrap-line-padding-0 {
  padding-left: 0;
  padding-right: 0;
}

%link-no-effects {
  a:active,
  a:hover,
  a:focus {
    text-decoration: none;
    color: inherit;
    outline: none;
  }
}

%links-external-style {
  a {
    word-break: normal;
    color: #95c731;
    &:hover {
      text-decoration: underline;
      color: #95c731;
    }
  }
}

%default-text-reset {
  font-family: "Open Sans";
  outline: none;
}

/* @link https://coderwall.com/p/lsmtyq/scss-clearfix */
%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

%product-detail-buy-btn {
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
  color: #9d9d9d;
  box-shadow: none;
}

@mixin context($old-context, $new-context) {
  @at-root #{selector-replace(&, $old-context, $new-context)} {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin progress-arrow($beforeLargeTriangleColor, $afterLargeTriangleColor, $beforeSmallTrianglesColor: transparent, $afterSmallTrianglesColor: transparent) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 25px solid $beforeSmallTrianglesColor;
    border-bottom: 25px solid $beforeSmallTrianglesColor;
    border-left: 9px solid $beforeLargeTriangleColor;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 1px;
    width: 0;
    height: 0;
    border-top: 25px solid $afterSmallTrianglesColor;
    border-bottom: 25px solid $afterSmallTrianglesColor;
    border-left: 9px solid $afterLargeTriangleColor;
  }
}

@mixin transition($rule) {
  transition: unquote($rule);
  -moz-transition: unquote($rule); /* Firefox 4 */
  -webkit-transition: unquote($rule); /* Safari and Chrome */
  -o-transition: unquote($rule); /* Opera */
  -ms-transition: unquote($rule); /* Explorer 10 */
}

@mixin vendor-prefix($name, $value) {
  $name: unquote($name);
  $value: unquote($value);
  @each $vendor in ("-webkit-", "-moz-", "-ms-", "-o-", "") {
    #{$vendor}#{$name}: #{$value};
  }
}

@mixin mixin-prefix-keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

body {
  @extend %default-text-reset;
  color: #000;
  background-color: #f7f7f7;
  margin: 0;
}

main {
  overflow: hidden;
}

input,
button {
  @extend %default-text-reset;
}

iframe {
  border: none;
}

hr {
  border: none;
}

ul {
  list-style: none;
}
p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

i,
em {
  font-style: italic;
}

a,
a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

.upper-text {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.bt-no-pd {
  @extend %bootstrap-line-padding-0;
}

.mrg-10-l {
  margin-left: 10px;
}

.mrg-15-t {
  margin-top: 15px;
}

.mrg-15-b {
  margin-bottom: 15px;
}

.mrg-15-r {
  margin-right: 15px;
}

.mrg-20-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mrg-30-b {
  margin-bottom: 30px;
}

.mrg-40-tb {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pdg-40-tb {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.p-relative {
  position: relative;
  height: 100%;
}

.color-blue {
  color: #95c731;
}
.color-orange {
  color: #fda100;
}

.loader-container {
  width: 100%;
  margin-bottom: 40px;
}

.hidden-see-more {
  display: none;
}

.hidden-ni {
  display: none;
}

.visible-i {
  display: block !important;
}

.hidden-i {
  display: none !important;
}

.modal-backdrop {
  z-index: 10;
}

label {
  &.error {
    font-size: 11px;
    text-align: left;
    text-transform: uppercase;
    padding-left: 15px;
    position: relative;
    top: -7px;
    color: red;
    &.fix-position {
      top: 0px;
      padding-left: 0px;
    }
  }
  &#captchaConfirmation-error {
    display: block;
    top: -20px;
  }
}

.errors-container {
  color: red;
  text-transform: uppercase;
  padding-left: 15px;
  font-size: 11px;
  text-align: left;
  .error {
    margin-bottom: 5px;
    font-weight: normal;
  }
  .errors-container {
    padding-left: 0px;
  }
}

textarea,
input[type="email"],
input[type="text"],
input[type="password"] {
  border-radius: 0;
}

.video {
  iframe {
    max-width: 100%;
    max-height: 100%;
  }
  p {
    height: 100%;
  }
}

.external-content {
  @extend %links-external-style;
}

// Fix loading problem with gallery styles
.vo-carousel-load-fix {
  opacity: 0;
  @include transition("opacity 250ms linear");
}

.slick-slider .arrow {
  z-index: 10;
}

/* Real person styles overide */
.realperson-text {
  border: none !important;
  margin-bottom: 0 !important;
}

.realperson-challenge {
  border: none !important;
  pointer-events: none;
}

.realperson-regen {
  display: none;
}

/* Select2 styles overide */
.select2-container {
  width: inherit !important;
  z-index: 5;
  .select2-selection--single {
    border: none;
    height: 40px;
    display: inline;
    &:focus {
      outline: 0;
    }
    .select2-selection__arrow {
      top: 5px;
    }
    .select2-selection__rendered {
      line-height: 36px;
      font-size: 11px;
      text-transform: uppercase;
      padding-left: 15px;
    }
  }
  .select2-results__option {
    color: #000;
    &[aria-selected="true"] {
      background-color: #d9d9d9;
      color: #000;
    }
    &.select2-results__option--highlighted {
      background-color: #d9d9d9;
      color: #000;
    }
  }
}

.select2-results__options {
  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}

li.select2-results__option {
  font-size: 11px;
  display: block;
  &[aria-disabled="true"] {
    display: none;
  }
  &[aria-disabled="false"] {
    display: block;
  }
}

%select2-dropdown-position {
  border-radius: 0;
  left: -1px !important;
}

.select2-dropdown--above {
  top: -1px;
  @extend %select2-dropdown-position;
}
.select2-dropdown--below {
  @extend %select2-dropdown-position;
}

/**
 Alert Alves was were! Mas teve que ser senao nao era possivel abrir a select em tablets/mobile. O margin deve ser de acordo com o tamanho do selector da select
*/
@media only screen and (max-width: 1199px) {
  .select2-dropdown--below {
    margin-top: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .select2-dropdown--above,
  .select2-dropdown--below {
    box-sizing: content-box;
  }
}

//Chrome will also apply highlighting to other elements such as DIV's used as modals. To prevent the highlight on those and all other elements as well, you can do
*:focus {
  outline: none;
}

.modal-backdrop.in {
  opacity: 0.75;
}

.more.default-theme {
  color: #9d9d9d;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;

  .icon-d {
    position: relative;
    &:before {
      font-size: 50px;
      position: absolute;
      top: -19px;
      left: -15px;
    }
  }

  .icon-D {
    position: relative;
    &:before {
      font-size: 50px;
      position: absolute;
      top: -19px;
      left: -15px;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Placeholder hidden when input gains focus
input[placeholder]:focus {
  @include placeholder {
    opacity: 0;
  }
}

.scroll-to-fix {
  position: absolute;
  top: -50px;
}

.scroll-to-content-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.button {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    button {
      pointer-events: none;
    }
  }
}

.panel-heading {
  .panel-title {
    color: #000;
  }
}

.env-warning-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  line-height: 1.4;
  cursor: pointer;
  .env-warning {
    padding: 15px;
    text-align: center;
    .env-title {
      font-size: 14px;
    }
    .env-message {
      font-size: 11px;
    }
    .hide-env-warning {
      font-size: 14px;
      font-weight: lighter;
    }
  }
}

.buy-btn {
  @extend %product-detail-buy-btn;
  font-size: 15px;
  border-radius: 0;
  height: 40px;
  line-height: 38px;
  width: 160px;
  cursor: default;
  padding: 0;

  &:hover {
    @extend %product-detail-buy-btn;
  }

  &:not(.digital-schoolbook).enabled {
    cursor: pointer;
    border: 1px solid #95c731;
    background-color: #95c731;
    color: #ffffff;

    &:hover {
      background-color: #000;
      border-color: #000;
    }
  }

  .icon-b {
    font-size: 50px;
    line-height: 38px;
    display: inline-block;
    vertical-align: top;
    margin-left: -15px;
  }

  .icon-b-text {
    font-family: "Open Sans";
    line-height: 40px;
    display: inline-block !important;
    vertical-align: top;
    font-size: 11px;
    font-weight: 600;
    padding: 0 !important;
  }

  &.digital-schoolbook {
    padding: 0 15px !important;
    width: auto;
    max-width: none;

    &.enabled {
      cursor: pointer;
      color: #000000;
      border: 1px solid #000000;
      background-color: unset;

      &:hover {
        color: #ffffff;
        border-color: #000000;
        background-color: #000000;

        .ev-logo {
          filter: invert(1);
        }
      }

      &.top-bar {
        color: #ffffff;
        border: 1px solid #ffffff;

        &:hover {
          color: #000000 !important;
          border-color: #ffffff;
          background-color: #ffffff;

          .ev-logo {
            filter: invert(0);
          }
        }

        .ev-logo {
          filter: invert(1);
        }
      }

      .ev-logo {
        opacity: 1;
      }
    }

    .ev-logo {
      display: inline-block !important;
      background-image: url("../images/ev_logo.svg");
      background-repeat: no-repeat;
      height: 38px;
      width: 128px;
      opacity: 60%;
      padding: 0 !important;
    }
  }
}

#modal-digital-schoolbooks {
  .modal-content {
    @media (min-width: 768px) {
      min-width: 730px;
    }
    #close-modal-digital-schoolbooks {
      display: flex;
      justify-content: end;
      .modal-close {
        display: block;
        top: auto;
        right: auto;
        color: #000000;
        position: relative;
        float: none;
        font-size: 30px;
        font-weight: bold;
      }
    }

    #content-modal-digital-schoolbooks {
      padding: 12px 0px 8px 0px;
      @media (min-width: 768px) {
        padding: 12px 60px 8px 60px;
      }
      h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        margin: 0px 0px 17.37px 0px;
        color: #333333;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0px 0px 17.37px 0px;
      }
    }

    #buttons-modal-digital-schoolbooks {
      padding: 8px 0px 20px 0px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      a:nth-child(2) {
        order: 1;
      }
      a:nth-child(1) {
        order: 2;
      }
      @media (min-width: 768px) {
        padding: 8px 60px 20px 60px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; /* Center the flex items horizontally */
        gap: 30px;
        a:nth-child(1) {
          order: 1;
        }

        a:nth-child(2) {
          order: 2;
        }
      }

      .btn.buy-btn.digital-schoolbook {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-grow: 1;
        height: 50px;
        @media (min-width: 768px) {
          max-width: 48%;
        }
        p {
          font-size: 14px;
        }
        &.generic {
          color: #000000;
          border-color: #ffffff;
          background-color: #ffffff;
          border: 1px solid #000000;
          &:hover {
            color: #ffffff;
            border-color: #000000;
            background-color: #000000;
          }
          p {
            font-weight: bold;
          }
        }
        &.product {
          color: #ffffff;
          border-color: #000000;
          background-color: #000000;
          &:hover {
            color: #000000;
            border-color: #ffffff;
            background-color: #ffffff;
            border: 1px solid #000000;
          }
          p {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@import "general/effects";
