.shopping-buttons{
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  @include transition("all .3s ease-in-out");


  .checkout-button {
    @include button-type-b(#95c731);
    @include context(".shopping-buttons", ".product-image .shopping-buttons") {
      position: absolute;
      bottom: 0;
    }
    margin-top: 1px;
    padding: 12px;
    width: 100%;
    height: 40px;
  }
  .buy-button{
    @include button-type-b(#95c731);
    position: relative;
    @include context(".shopping-buttons", ".product-image .shopping-buttons") {
      position: absolute;
      bottom: 0;
    }
    margin-top: 1px;
    width: 100%;
    height: 40px;

    .text{
      position: absolute;
      top: 50%;
      right: 40px;
      margin-top: -7px;
      font-weight: 600;
    }
    .icon{
      position: absolute;
      left: 25px;
      top: -7px;
      font-size: 50px;
    }
  }

  .btn-go-to-cart{
    position: relative;
    @include button-type-a(#000);
    @include context(".shopping-buttons", ".product-image .shopping-buttons") {
      position: absolute;
      bottom: 45px;
    }
    margin-top: 1px;
    padding: 12px;
    background-color: #fff;
    width: 100%;
    height: 40px;
    &:after {
      content: '';
      height: 5px;
      background-color: #FFF;
      position: absolute;
      bottom: -6px;
      left: -1px;
      right: 0;
      width: calc(100% + 2px);
    }
    &:before {
      content: '';
      height: 5px;
      background-color: #FFF;
      position: absolute;
      top: -6px;
      left: -1px;
      right: 0;
      width: calc(100% + 2px);
    }
  }
}
.product-image:hover .shopping-buttons, .product-info-img:hover .shopping-buttons {
  //@todo effectvisibility: visible;
  bottom: 0;
}