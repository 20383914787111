.view-types{
    margin-right: 5px;
    .type{
        margin: -12px -5px -5px -5px;
        font-size: 50px;
        float: left;
        width: 50px;
        height: 50px;
        &.active{
            color: #95c731;
            cursor: auto;
        }
        &.first{
            margin-left: 0px;
        }
    }
}