
@import '../../partials/general';
@import '../../partials/desktop/general';
@import '../../partials/desktop/header';
@import '../../partials/desktop/footer';
@import '../../widgets/breadcrumbs/general';
@import '../../widgets/social/general';
@import '../partials/product-list/desktop';
@import "filters_general";

main.overflowIn {
  overflow: initial;
}

.search-filters {
  height: 40px;
  .filters-picker{
    .btn-group {
      vertical-align: top;
      float: left;
    }
    .filters-item-group {
      display: inline-block;
      float: left;
      li:hover {
        background-color: #d9d9d9;
      }
      .btn-group {
        margin-right: 15px;
        &.open {
          .filters-item {
            z-index: 998;
          }

          .dropdown-menu {
            overflow: auto;
          }
        }
      }
    }
    .filters-item {
      height: 38px; // To compensate the border!
      padding: 0 30px 0 15px;
      float: left;
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      position: relative;
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      box-sizing: content-box;
      width: auto;
      &.disabled {
        cursor: not-allowed;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #9d9d9d;
        &.Preço,
        &.etária {
            display: none;
        }
      }
      &.order {
        border: 1px solid #000000;
        width: 255px;
        margin-left: 40px;
        margin-right: 30px;
        background-color: transparent;
        &[aria-expanded='true'] {
          box-shadow: none;
        }
        + .dropdown-menu {
          margin-left: 40px;
          width: 255px;
          li {
            font-size: 15px;
            font-weight: 600;
            text-align: left;
            color: #000000;
            padding: 10px 15px 10px 15px;
            &:first-child {
              padding-top: 15px;
            }
            &:last-child {
              padding-bottom: 15px;
            }
            &.current {
              pointer-events: none;
              p{
                color: #9d9d9d;
              }
            }
            &:not(.current):hover {
              background-color: #d9d9d9;
            }
          }
        }
        p {
          font-weight: bold;
          color: #95c731;
          margin-left: 5px;
          display: inline-block;
        }
        span {
          margin-left: 0;
          &:before {
            left: -25px;
          }
        }
      }
      &:not(.order){
        &[aria-expanded='true'] {
          border: solid black 1px;
          border-bottom: none;
          background-color: #ffffff;
          padding-bottom: 27px;
          box-sizing: content-box;
          margin: 0;
          z-index: 11;
          box-shadow: none;
        }
      }
      span {
        position: relative;
        &:before {
          width: 0;
          height: 0;
          font-size: 50px;
          position: absolute;
          top: -19px;
          left: -30px;
        }
      }
    }
    .dropdown-menu {
      box-shadow: none;
      border-radius: 0;
      border: 0;
      width: 350px;
      padding: 0;
      margin-top: -1px;
      margin-left: 0;
      z-index: 12;
      ul {
        border: solid black 1px;
        z-index: 99;
        position: relative;
        cursor: pointer;
        padding-bottom: 10px;
        li {
          position: relative;
          padding: 5px 15px;
          &:first-child {
            padding-top: 10px;
            .icon-plus, .icon-z {
              top: 17px;
              z-index: 999;
            }
          }
          p {
            font-size: 14px;
            text-align: left;
            color: #000000;
          }
          .icon-plus, .icon-z {
            position: absolute;
            right: 15px;
            top: 13px;
            &:before{
              font-weight: bold;
              font-size: 15px;
              color: #000000;
            }
            &.disabled {
              &:before{
                color: #9d9d9d;
              }
            }
          }
          &[aria-expanded='true'] {
            background-color: #d9d9d9;
            p {
              font-weight: bold;
              background-color: #d9d9d9;
            }
          }
        }
        ul{
          border: none;
          &[aria-expanded='true'] {
            li {
              padding: 10px 15px 10px 50px;
              &:first-child {
                padding-top: 20px;
                .icon-plus, .icon-z {
                  top: 22px;
                }
              }
              .icon-plus, .icon-z {
                top: 12px;
              }
              &[aria-expanded='true'] {
                background-color: #f7f7f7;
                p {
                  color: #95c731;
                  background-color: transparent;
                }
              }
            }
          }
          ul {
            border: none;
            &[aria-expanded='true'] {
              li {
                padding: 10px 15px 10px 80px;
                text-decoration: underline;
                &:first-child {
                  padding-top: 20px;
                  .icon-plus, .icon-z {
                    top: 22px;
                  }
                }
                .icon-plus, .icon-z {
                  top: 2px;
                }
              }
            }
          }
        }
      }
    }
    span {
      &.icon-f {
        left: -15px;
      }
      &:before {
        font-size: 50px;
      }
    }
  }
}
