body.lock.cart-open {
  height: auto;
  overflow: hidden;
  width: 100%;
  position: absolute;
}

#shopping-cart-popup-container-inner {
  width: 385px;
}

#shopping-cart-popup-container {
  font-family: "Open Sans";
  z-index: 999;
  height: 0px;
  display: block;
  opacity: 1;

  #shopping-cart-popup-no-items{
    margin-bottom: -15px;
    .empty-cart-notification{
      background-color: #95c731;
      min-height: 40px;
      .col-xs-2{
        max-height: 40px;
      }
    }
    .items-notifications-icon{
      font-family: PE_icons;
      font-size: 50px;
      color: #fff;
      position: relative;
      top: -15px;
      overflow: hidden;
      max-height: 50px;
      width: 50px;
    }
    .items-notifications-text {
      font-weight: 300;
      line-height: 40px;
      font-size: 15px;
      color: #fff;
    }
  }

  .overlay-cart.active {
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  #shopping-cart-popup-container-inner {
    display: inline-block;
    float: right;
  }

  #shopping-cart-popup-outer {
    -webkit-box-shadow: 4px 40px 85px -21px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 40px 85px -21px rgba(0,0,0,0.75);
    box-shadow: 4px 40px 85px -21px rgba(0,0,0,0.75);
    background-color: #f7f7f7;
    padding: 0 15px 15px 15px;
    display: none;
  }

  #shopping-cart-popup {
    &>.row {
      min-height: 40px;
    }
    .items-notifications.add-item-notification,
    .items-notifications.remove-item-notification {
      display: none;
    }
    .items-notifications.add-item-notification {
      background-color: #95c731;
    }
    .items-notifications.remove-item-notification {
      background-color: #d14836;
    }
    .items-notifications {
      padding-left: 0;
      padding-right: 0;
      color: #ffffff;
      height:40px;
      .items-notifications-icon {
        font-family: PE_icons;
        font-size: 50px;
        color: #ffffff;
        position: relative;
        top: -15px;
        left: 35px;
      }
      .items-notifications-text {
        font-weight: 300;
        line-height: 40px;
        font-size: 15px;
      }
    }

    .small-shopping-cart-item {
      margin-bottom: 20px;
      overflow: auto;
      .row {
        background-color: #f7f7f7;
        margin-top: 10px;
        margin-right: 0;
        overflow: hidden;
        .item-image {
          width: 65px;
          float: left;
        }
        .item-description {
          float: left;
          height: 100px;
          width: 150px;
          margin-left: 10px;
          position: relative;
          .item-title {
            font-size: 18px;
            line-height: 22px;
            text-align: left;
            color: #000000;
          }
          .small-shopping-cart-alter-quant {
            width: 18px;
            height: 18px;
            text-align: center;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          .item-quant {
            width: 20px;
            height: 20px;
            border: 1px solid #000000;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
            color: #000000;
            margin-top: 36px;
            position: absolute;
            bottom: 0;
            box-sizing: content-box;
            &.disabled{
              border: none;
              input[type=number] {
                background-color: transparent;
              }
            }
          }
        }
        .item-price-container {
          font-weight: bold;
          float: right;
          .item-price {
            font-size: 18px;
            line-height: 22px;
            text-align: right;
            color: #000000;
            float:left;
            .item-price-currency {
              margin-left: 5px;
            }
          }
          .item-remove-action {
            float: right;
            &:hover {
              cursor: pointer;
            }
            .item-remove-icon {
              font-family: 'PE_icons';
              font-size: 25px;
              font-weight: bold;
              line-height: 25px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .small-shopping-cart-divider {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: #000000;
    }

    .small-shopping-cart-summary {
      .items-container {
        float: right;
        .left {
          width: 240px;
          float: left;
          display: inline-block;
        }
        &.first-row {
          margin-top: 30px;
          &.no-savings {
            margin-bottom: 40px;
          }
        }
        &.second-row {
          margin-top: 5px;
          margin-bottom: 40px;
        }
        &.third-row {
          margin-bottom: 20px;
          margin-top: -25px;
        }
        &.fourth-row {
          margin-bottom: -2px;
          &.negative-m-top {
            margin-top: -25px;
          }
        }
        .num-items,
        .num-items-label {
          font-size: 18px;
          line-height: 22px;
          text-align: right;
          color: #000000
        }
        .total-value-label,
        .total-value,
        .total-value-currency {
          font-size: 18px;
          line-height: 22px;
          text-align: right;
          color: #000000;
          font-weight: bold;
        }
        .savings-label {
          font-size: 18px;
          line-height: 22px;
          text-align: right;
          color: #95c731;
        }
        .savings-value,
        .savings-value-currency {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          text-align: right;
          color: #95c731;
        }
        .free-postage-label,
        .postage-time-label {
          font-size: 14px;
          text-align: right;
          color: #000000;
        }
        .free-postage-icon,
        .postage-time-icon {
          font-family: PE_icons;
          font-size: 50px;
          color: #95c731
        }
      }
    }

    .small-shopping-cart-buttons {
      //margin-bottom: 15px;

      .col-xs-6{
        @extend %bootstrap-line-padding-0;
      }

      #btn-shopping-cart,
      #btn-checkout {
        float: right;
        width: 160px;
        height: 40px;
        line-height:30px;
        border-radius: 0;
      }
      #btn-shopping-cart {
        float: left;
        border-style: solid;
        border-width: 1px;
        border-color: #000000;
        font-size: 11px;
        text-align: center;
        vertical-align: middle;
        text-transform: uppercase;
        color: #000000;
        background: transparent;
      }
      #btn-checkout {
        color: #95c731;
        font-size: 11px;
        text-align: center;
        vertical-align: middle;
        text-transform: uppercase;
        color: #ffffff;
      }
      .btn-primary {
        background: #95c731;
        border: none;
      }
    }
  }
}